<template>
  <b-modal
    id="set-modal"
    ref="my-modal"
    :title="$t('SET_MILESTONE')"
    :ok-title="set.settime_id ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD')"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <b-form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <!-- set date and time -->
        <b-col cols="6">
          <b-form-group>
            <h5>{{ $t('SET_DATE_TIME') }}</h5>
            <flat-pickr
              v-model="set.settime"
              type="text"
              class="form-control"
              :config="{minDate: formatSqlDate(competition.startdate),maxDate:formatSqlDate(competition.enddate), enableTime: true,dateFormat: 'Y-m-d H:i'}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-2 md-2"
            @click="deleteSet()"
          >
            <feather-icon
              icon="Trash2Icon"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BModal, VBModal, BRow, BCol, BButton,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'

export default {
  components: {
    BForm,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    BButton,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [mixinDate],
  model: {
    prop: 'editSet',
  },
  props: {
    competition: {
      type: Object,
      required: true,
    },
    editSet: {
      type: Object,
      default: () => {},
    },
    getEvents: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      set: {
        settime_id: 0,
        comp_id: 0,
        settime: null,
        isCalculated: 0,
      },
      nameState: null,
    }
  },
  watch: {
    editSet: {
      handler() {
        if (this.editSet) {
          this.set = this.editSet
          const setDate = moment(this.editSet.settime, this.DASH_DATE_TIME_FORMAT)
          this.set.settime = setDate.toDate()
        }
      },
    },
  },
  setup() {
    const {
      successMessage, errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
    }
  },
  methods: {
    ...mapActions('settime', ['fetchAllSettimes', 'createSettime', 'updateSettime', 'deleteSettime']),
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    resetModal() {
      this.nameState = null
      this.set = {}
    },
    deleteSet() {
      this.deleteSettime(this.set.settime_id).then(async () => {
        await this.fetchAllSettimes(this.set.comp_id)
        this.getEvents()
        this.resetModal()
        this.$bvModal.hide('set-modal')
        this.successMessage(this.$i18n.t('MESSAGE.SET_DELETED'))
      })
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.set.comp_id = this.competition.ID
      if (this.set.settime_id) {
        this.updateSettime(this.set).then(async () => {
          await this.fetchAllSettimes(this.set.comp_id)
          this.getEvents()
          this.resetModal()
          this.$bvModal.hide('set-modal')
          this.successMessage(this.$i18n.t('MESSAGE.SET_UPDATED'))
        })
      } else {
        this.createSettime(this.set).then(async () => {
          await this.fetchAllSettimes(this.set.comp_id)
          this.getEvents()
          this.resetModal()
          this.$bvModal.hide('set-modal')
          this.successMessage(this.$i18n.t('MESSAGE.SET_CREATED'))
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
