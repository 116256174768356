// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import { USER_ROLE, PROJECT_ROLE } from '@/constants/static.json'

export const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

export const sortCompare = key => (a, b) => {
  const fieldA = a[key]
  const fieldB = b[key]

  let comparison = 0
  if (fieldA > fieldB) {
    comparison = 1
  } else if (fieldA < fieldB) {
    comparison = -1
  }
  return comparison
}

export const getRandomInt = (min, max) => {
  if (min > max) {
    const temp = max
    /* eslint-disable no-param-reassign */
    max = min
    min = temp
    /* eslint-enable */
  }

  if (min <= 0) {
    return Math.floor(Math.random() * (max + Math.abs(min) + 1)) + min
  }
  return Math.floor(Math.random() * max) + min
}

export const randomDate = (start, end) => {
  const diff = end.getTime() - start.getTime()
  const newDiff = diff * Math.random()
  const date = new Date(start.getTime() + newDiff)
  return date
}

export const canDeleteGame = data => {
  let result = true
  const today = moment(new Date()).format('YYYY-MM-DD')
  const startDate = moment(data.startdate).format('YYYY-MM-DD')
  if (today >= startDate) {
    result = false
  }
  if (data.isArchive === -1) {
    result = false
  }
  return result
}

export const isBeforeToday = date => {
  const today = moment(new Date()).format('YYYY-MM-DD')
  const formattedEndDate = moment(date).format('YYYY-MM-DD')
  return today <= formattedEndDate
}

export const isDateUpToToday = date => {
  const today = moment(new Date()).format('YYYY-MM-DD')
  const formattedEndDate = moment(date).format('YYYY-MM-DD')
  return today >= formattedEndDate
}

export const canEditDeleteGame = data => {
  let result = true
  const today = moment(new Date()).format('YYYY-MM-DD')
  const endDate = moment(data.enddate).format('YYYY-MM-DD')
  if (today > endDate) {
    result = false
  }
  if (data.isArchive === -1) {
    result = false
  }
  return result
}

export const countingObject = data => {
  let usersCount = 0; let groupCount = 0; const users = []
  const playGroup = data.filter(
    group => (group.groupcomplevel !== -2),
  )
  groupCount = playGroup.length
  playGroup.forEach(group => {
    group.users.forEach(user => {
      // eslint-disable-next-line no-plusplus
      usersCount++
      users.push(user)
    })
  })
  return { usersCount, groupCount, users }
}

export const resolveTeamRole = role => {
  switch (role) {
    case -2:
      return {
        id: 1,
        name: 'Coach',
        description_1: 'Coaches',
        description_2: 'Coaching Team',
        variant: 'success',
        Icon: 'UserIcon',
      }
    case 0:
      return {
        id: 2,
        name: 'Player',
        description_1: 'Players',
        description_2: 'Team',
        variant: 'warning',
        Icon: 'UserIcon',
      }
    default:
      return {
        id: 0,
        name: '',
        variant: '',
        Icon: '',
      }
  }
}

export const resolveGameStateVariant = status => {
  switch (true) {
    case status === 0:
      return {
        id: 2, name: 'Paused', variant: 'warning', icon: 'PlayCircleIcon', i18n: 'RUN',
      }
    case status === 2:
      return {
        id: 0, name: 'Running', variant: 'success', icon: 'PauseCircleIcon', i18n: 'PAUSED',
      }
    default:
      return { id: 1, name: '', variant: 'info' }
  }
}

export const resolveUserStatusVariant = status => {
  switch (true) {
    case status === 0:
      return { id: 1, name: 'Pending', variant: 'warning' }
    case status > 0:
      return { id: 2, name: 'Active', variant: 'success' }
    case status < 0:
      return { id: 3, name: 'Inactive', variant: 'secondary' }
    default:
      return { id: 0, name: 'Error', variant: 'danger' }
  }
}

export const resolveUploadUserStatusVariant = status => {
  switch (true) {
    case status === 1:
      return { id: 1, name: 'Pending', variant: 'success' }
    case status === 2:
      return { id: 2, name: 'Completed', variant: 'warning' }
    case status === -5:
      return { id: 3, name: 'Pending', variant: 'info' }
    default:
      return { id: 0, name: 'Error', variant: 'danger' }
  }
}

export const resolveUserRole = role => {
  let result = {}

  switch (role) {
    case USER_ROLE.ADMIN:
      result = {
        id: 1, name: 'Admin', variant: 'primary', Icon: 'UserIcon',
      }
      break
    case USER_ROLE.COMPANY_ADMIN:
      result = {
        id: 2, name: 'Company admin', variant: 'warning', Icon: 'UserIcon',
      }
      break
    case USER_ROLE.PLAYER:
      result = {
        id: 3, name: 'Player', variant: 'success', Icon: 'UserIcon',
      }
      break
    case USER_ROLE.INSPECTOR:
      result = {
        id: 4, name: 'Inspector', variant: 'info', Icon: 'UserIcon',
      }
      break
    case USER_ROLE.ANALYZER:
      result = {
        id: 5, name: 'Analyzer', variant: 'danger', Icon: 'UserIcon',
      }
      break
    default:
      result = {
        id: 0, name: '', variant: '', Icon: '',
      }
      break
  }

  return result
}

export const resolveProjectRole = role => {
  let result = {}

  switch (role) {
    case PROJECT_ROLE.PROJECT_ADMIN:
      result = {
        id: 1, name: 'Project Admin', variant: 'success', Icon: 'UserIcon',
      }
      break
    case PROJECT_ROLE.MEMBER:
      result = {
        id: 2, name: 'Member', variant: 'warning', Icon: 'UserIcon',
      }
      break
    default:
      result = {
        id: 0, name: '', variant: '', Icon: '',
      }
      break
  }

  return result
}

export const resolveBatchUrl = index => {
  const icons = {
    // eslint-disable-next-line global-require
    1: require('@/assets/images/icons/gold.png'),
    // eslint-disable-next-line global-require
    2: require('@/assets/images/icons/silver.png'),
    // eslint-disable-next-line global-require
    3: require('@/assets/images/icons/bronze.png'),
    // eslint-disable-next-line global-require
    4: require('@/assets/images/icons/place.png'),
  }

  // Default to gold if the index is out of bounds
  const iconPath = icons[index] || icons[1]

  // eslint-disable-next-line global-require, import/no-dynamic-require
  return iconPath
}

export const resolvePackage = packageId => {
  switch (packageId) {
    case 1:
      return {
        id: 1,
        name: 'Free',
        variant: 'primary',
      }
    case 2:
      return {
        id: 2,
        name: '6 Months',
        variant: 'warning',
      }
    case 3:
      return {
        id: 3,
        name: 'Annual',
        variant: 'info',
      }
    case 4:
      return {
        id: 4,
        name: '2 Years',
        variant: 'success',
      }
    default:
      return {
        id: 0,
        name: '',
        variant: '',
      }
  }
}
